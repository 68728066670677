import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

const HeaderSlider = (data) => {
  const images = data.headerImages;
  const length = images ? images.length : 0;

  return (
  <CarouselProvider 
    autoPlay
    naturalSlideWidth={1600}
    naturalSlideHeight={length > 1 ? 400 : 350}
    orientation={"horizontal"}
    totalSlides={length}
    interval={5000}
    isPlaying={true}

    style={{
      padding: "0",
      width: "100%",
      maxWidth: "100%"
    }}
  >   
    <Slider disableAnimation={true}>
      { images.map( ( image, index ) => {
        return <Slide key={image.id} index={index}><GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={"Header "+image.name} key={image.id} style={{maxWidth: "100%", width: "auto"}} /></Slide>
      }
      )}
    </Slider>
  </CarouselProvider> 
  )
}; 
  
export default HeaderSlider;

