import React from "react";

import {
    FacebookShareButton, TwitterShareButton, LinkedinShareButton, EmailShareButton
} from "react-share";

// import {
//     FacebookShareCount
// } from "react-share";

import {
    FacebookIcon, TwitterIcon, LinkedinIcon, EmailIcon
} from "react-share";

import "../styles/flex.less"

const SocialShare = props => {
    const { url, title, excerpt } = props;
    // const fb = 'https://www.facebook.com/sharer/sharer.php?u=https://mariohernandez.io${ url }';
    // const twitter = 'https://twitter.com/intent/tweet/?text=${ title }&url={ url }';
    // const linkedIn = 'https://www.linkedin.com/shareArticle?mini=true&url=https://mariohernandez.io${ url }&title=${ title }&source=${ title }';
    // const google = 'https://plus.google.com/share?url={ url }';
    // const email = "";
    let clazz = "social-column";
    let size = 48;

    if (props.orientation === "row") {
        clazz = "social-row";
        size = 36;
    }

    return (
        <ul className={clazz}>
            <li>
                <div style={{ width: size }}>
                <span>Share</span>
                </div>
            </li>    
            <li>
            <FacebookShareButton 
                url={url} target="blank" 
                quote={title}
                children={"share"}
                className="social-button"
            > 
                <FacebookIcon 
                    size={size}
                    button={false}
                 />
            </FacebookShareButton>
            </li>
            <li>
            <TwitterShareButton 
                url={url} 
                title={title}
                className="social-button"
            > 
                <TwitterIcon 
                    size={size}
                    button={false}
                 />
            </TwitterShareButton>
            </li>
            <li>
            <LinkedinShareButton 
                url={url} target="blank" 
                quote={title}
                className="social-button"
            > 
                <LinkedinIcon 
                    size={size}
                    button={false}
                 />
            </LinkedinShareButton>
            {/* <GooglePlusShareButton 
                url={fb} target="blank" 
                quote={title}
                className="social-button"
            > 
                <GoogleIcon 
                    size={32}
                    button={false}
                 />
                <span>shaare</span>
            </GooglePlusShareButton> */}
            </li>
            <li>
            <EmailShareButton 
                url={url} target="blank" 
                subject={title}
                separator= " | "
                body={"Check out this post: " + excerpt}
                className="social-button"
            > 
                <EmailIcon 
                    size={size}
                    button={false}
                 />
            </EmailShareButton>
            </li>
        </ul>
    )
};
  
export default SocialShare;
