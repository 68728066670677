
import React from "react";
import Link from "gatsby-link";
// import PropTypes from "prop-types";
// import Media from "react-media";
import Seo from "./seo.js";
import Helmet from "react-helmet"
import Header from "./header.js";
import Sidebar from "./sidebar.js";
import Footer from "./footer.js";
import TagCloud from "./tag-cloud.js";
import SocialShare from "./social-share";
import "../styles/breakpoints.less"
import "../styles/colors.less"
import "../styles/background-colors.less"
import "../styles/blog-typography.less"
import "../styles/flex.less"
import "../styles/blogcontent.less"

const TemplateWrapper = content => {
  const { accumulation, category, share } = content;
  const realContent = (share && share.realContent === true);

  return (
  <div className="wrapper">
    <Seo share={share} category={category} />
    <Helmet>
      <link rel="stylesheet" href="/styles/prism.css" />
    </Helmet>  
    <div className="flex-wrapper"> 
      <Header headerImages = {content.headerImages} category = {category} />
        <div className="flex-mid-tier">
          <div className="flex-content cst-blog-content">
            <div style={{textAlign: "center", float: "center", display: "inline-block"}}>
            {realContent ? <SocialShare orientation="row" url={share.url} title={share.title} excerpt={share.excerpt} /> : ""}
            </div>
            {content.children}
          </div>
          <div className="flex-sidebar">
            <Sidebar
              title="CyberSym Blogs"
              content={
                <ul className="menu" style={{marginBottom: "0"}}>
                  <li className="li"><Link className="a" to="/" ><span>CyberSym Blogs Home</span></Link></li>
                  <li className="li"><Link className="a" to="/science-society/">{<span>Science &amp; Society</span>}</Link></li>
                  <li className="li"><Link className="a" to="/tech-intersection/">{<span>Technology Intersection</span>}</Link></li>
                  <li className="li"><Link className="a" to="/ultrarunning/">{<span>Ultrarunning Edge</span>}</Link></li>
                  <li className="li"><a href="https://cybersym.com" className="a"><span>CyberSym Technologies Home</span></a></li>
                </ul>		
              }
            />
            { category && category === "ultrarunning" ?
            <Sidebar
              title="Special Topics"
              content={
                <ul className="menu" style={{marginBottom: "0"}}>
                  <li className="li"><Link className="a" to="/ultrarunning/ultrarunning-biochem">{<span>Biochemical Strategies for Ultrarunning</span>}</Link></li>
                </ul>		
              }
            />
            : "" }
            { accumulation && accumulation !== "" ? <TagCloud accumulation = {accumulation} /> : "" }
            { realContent ? <SocialShare orientation="column" url={share.url} title={share.title} excerpt={share.excerpt} /> : ""}
          </div>
        </div>
      <Footer category={category} />
    </div>
    <script src="/utils/prism.js"></script>
    {/* <script
        dangerouslySetInnerHTML={{ __html: `  
          function highlightCode() {
              var pres = document.querySelectorAll("pre><code");
              for (var i = 0; i < pres.length; i++) {
                  prism.highlightBlock(pres[i]);
              }
          },
          highlightCode();
        `}}
      /> */}
  </div>
  )
};  

// TemplateWrapper.propTypes = {
// //    children: PropTypes.object
//     content.children: PropTypes.arrayOf(PropTypes.object).isRequired
// };

export default TemplateWrapper;
