import React from "react";
import { countWords } from "../utils/word-count.js";
import { formatTags } from "../utils/word-count.js";

import '../styles/flex.less';

class TagCloud extends React.Component {
  constructor ( props ) {
    super(props);
    this.state = { isClient: false };
  }

  componentDidMount() {
    this.setState({ isClient: true });
    if (typeof(window) !== 'undefined')
      console.log("Changing isClient value to true");
  }

  render() {
  return (
  <div className="flex-sidebar-content"> 
    {
      this.state.isClient ?
        <div 
          className="tag-cloud"
        >    
          {formatTags(countWords(this.props.accumulation))} 
        </div>
      : ""
    }
  </div> 
  )
  } 
}

export default TagCloud;

