import React from "react";
import Link from "gatsby-link";

import "../styles/blog-typography.less";

const TopMenu = (data) => {
  let category = data.category;

  if (category.length > 0)
    category += '/';

  return (
  <div className="top-menu"
    style={{
      display: "inline-grid",
      gridTemplateColumns: "72px 72px 72px 72px", 
      paddingTop: "0.25rem" 
    }}
   >
    <Link to={'/'+ category} style={{fontWeight: "700"}} >Home</Link>
    <Link to={'/'+ category +'contact'} style={{fontWeight: "700"}}>Contact</Link>
    <Link to={'/'+ category +'bio'} style={{fontWeight: "700"}}>Bio</Link>
    <Link to={'/'+ category +'about'} style={{fontWeight: "700"}}>About</Link>
  </div> 
  )  
}  

export default TopMenu;
