
import React from "react";
import Link from "gatsby-link";
import HeaderSlider from "./header-slider.js";
import TopMenu from "./top-menu.js";

import "../styles/flex.less";
import ultra_logo from "../images/ultra_glow.png";
import tech_logo from "../images/technology_logo_2019-05-08.png";
import cst_logo from "../images/cst_blogs_logo_2019-05-10.png";
import scisoc_logo from "../images/sci_soc_logo_2019-05-08.png";

const Header = (data) => {
  const category = data.category;
  let logo = cst_logo;
  let alt = "CyberSym Blogs";

  if (data.category === "tech-intersection") {
    logo = tech_logo;
    alt = "Tech Intersection"
  } else if (data.category === "ultrarunning") {
    logo = ultra_logo;
    alt = "Ultrarunning Edge"
  } else if (data.category === "science-society") {
    logo = scisoc_logo; 
    alt = "Science & Society"
  }  

  return (
  <header 
    className="flex-header"
    style={{
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
    }}
  >
    <div style={{zIndex: "0", position: "relative"}}>
      <HeaderSlider headerImages = {data.headerImages} category={category} />
      <Link to={'/'+ category}>
        <img src={logo} alt={alt} 
          style={{
            position: "absolute",
            left: "4%",
            top: "2%"
          }}
        /> 
      </Link>
      <div style={{
          zIndex: "3",
          position: "absolute",
          right: "0.5rem",
          top: "0.25rem"
        }} 
      >    
        <TopMenu category={data.category} /> 
      </div>   
    </div>
  </header>
  )
}

export default Header;

