
import React from "react";
import Link from "gatsby-link";

import "../styles/flex.less";

const Footer = ( data ) => {
  let category = data.category;

  if (category.length > 0)
    category += '/';

  return (
  <footer className="flex-footer">
    <div 
      style={{width: "50%", float: "left", textAlign: "right", paddingBottom: "1.0rem", paddingRight: "2.0rem"}}
    >
      <Link className="a" to={"/" + category + "contact"} ><span>Contact</span></Link> 
    </div>
    <div 
      style={{width: "50%", float: "right", textAlign: "left", paddingBottom: "1.0rem", paddingLeft: "2.0rem"}}
    >
      <Link className="a" to={"/" + category + "privacy-policy"} ><span>Privacy Policy</span></Link> 
    </div>
    <div>
      Copyright &copy; 2008-2022 <a className="a" href="https://cybersym.com">CyberSym Technologies</a>, All Rights Reserved
    </div>
  </footer>
  )
};

export default Footer;

