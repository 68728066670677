import React from "react";
import randomColor from 'randomcolor';

let ignore = new Set(['a', 'about', 'actual', 'actually','after', 'along', 'already', 'also', 'all', 'an', 'and', 'another', 'are', 'around', 'as', 'at', 
        'bad', 'be', 'because', 'been', 'before', 'better', 'beside', 'besides', "between", 'both', 'but', 'by', 'can', 'come', 'could', 'different', 'do', 'does', 'done', 'don\'t', 'during', 'each', 'either', 'entire', 
        'enough', 'even', 'ever', 'every', 'first', 'for', 'from', 'get', 'gets', 'getting', 'good', 'got', 'gotten', 'had', 'has', 'have', 
        'he', 'here', 'heavily', 'how', 'however', 'https', 'i', 'if', 'in', 'into', 'is', 'it', 'it\'s', 'just', 'know', 'last', 'like', 'little', 'long', 'made', 'make', 'many', 'me', 'might', 
        'more', 'most', 'much', 'must', 'my', 'no', 'necessary', 'need', 'needs', 'neither', 'not', 'never', 'of', 'on', "only", 'or', 'other', 'our', 'over', 
        'pm', 'really','s', 'short', 'seem', 'seems','several', 'she', 'should', 'so', 'some', 'something', 'sometime', 'sometimes', 'somewhere', 'still', 'such', 't', 'than', 'that', 
        'the', 'their', 'them', 'then', 'there','therefore','they', 'these', 'this', 'those', 'though', 'through', 'to', 'unnecessary', 'us', 'use', 'used', 'using', 'very', 'want', 
        'was', 'we', 'were', 'what', 'when', 'where', 'whether', 'which', 'who', 'whose', 'will', 'with', 'won\'t', 'worse', 'would', 'you', 'your', 
        'yours', '<div', '<div>', '</div>', '//www']);

let maxCount = 1;
let minCount = 0;

export function countWords( content ) {
  /* Need to split up words, but NOT split on apostrophes. Solution is to 
  first replace punctuation we don't care about (like fullstops and 
  commas) with spaces, then extract all non-whitespace sequences. */
  //let text = self.areaToPlainText(page, area);
  // let lcText = content.toLowerCase().replace(/[\.\,\;\:\!\?\(\)\&]/g, ' ');
  // let caseText = content.replace(/[\.\,\;\:\!\?\(\)\&]/g, ' ');
  content.replace('\n', ' ');
  content.replace('\r', ' ');

  let lcText = content.toLowerCase().replace(/[.,;:!?()&]/g, ' ');
  let caseText = content.replace(/[.,;:!?()&]/g, ' ');
  let word, cWord;
  let counts = {};
  let m = lcText.split(' ');
  let c = caseText.split(' ');
  let numWords = m.length;
  let wordCountData = {};

  for (let i = 0; i < numWords; i++) {
    word = m[i];
    cWord = c[i];

    if (content.includes(word) && word.length > 3 && ! ignore.has(word)) {
      counts[word] = (counts[word] || 0) + 1; 
    } else if (word.length < 2 || (caseText.includes(cWord.substring(0, 1) + word.substring(1, word.length)))) { 
      continue;    
        // Ignore capitalized words for now    
    } else if (cWord.substring(1, 2) !== word.substring(1, 2)) { // Must be some kind of acronym
      counts[cWord] = (counts[cWord] || 0) + 1;
    }
  }

  let results = [];
  let colorOptions = {luminosity: 'light'};

  for (word in counts) {
    if (counts[word] > 1) {
      results.push({'tag': word, 'count': counts[word], 'color': randomColor(colorOptions)});
    }    
  }

  results = results.sort(function(a, b) {
      return (b.count - a.count);
  });

  let length = Math.min(50, results.length);
  let index1, index2, count = 0;
  let temp

  if (length > 0) {
    minCount = results[length - 1].count;
    maxCount = results[0].count;
  } 

  if (length > 2)
    maxCount = results[2].count; 

  // Randomize the array
  while (count++ < length) {
      index1 = Math.floor(length*Math.random());
      index2 = Math.floor(length*Math.random());
      temp = results[index1];
      results[index1] = results[index2];
      results[index2] = temp;
  }

  wordCountData = results.slice(0, length); 

  return wordCountData;
};

export function formatTags( countData ) {
  const maxFontRange = 1.6;
  const maxx = maxCount;
  const minn = minCount;
  const invScale = ( maxx === minn ? 1 : (maxx - minn) / (maxFontRange - 0.01) );
    
  function normalizedSize( count ) {
    const ccount = Math.min(count, maxx);

    return (0.8 + (ccount - minn) / invScale);
  };

  return ( countData
  .filter( countItem => countItem.count > 0)
  .map( ( countItem ) => {
      const size = normalizedSize(countItem.count);
      const tagStyle = {
        padding: "0",
        lineHeight: "0.8em",
        paddingLeft: 0.15*size + "rem",
        paddingRight: 0.15*size +"rem",
        wordBreak: "normal",
        display: "inline-block",
        color: countItem.color,
        fontSize: size + "rem",
      };

      return (
        <span 
          key={countItem.tag}
          style={tagStyle}
        >
          {' ' + countItem.tag} 
        </span>
      );
    }
    )
  )
}
  
