import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ share, category, url, title, description, keywordsString, meta }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
          keywords
          ultrarunning {
            title
            description
            author
            siteUrl
            keywords
          }
          science_society {
            title
            description
            author
            siteUrl
            keywords
          }
          tech_intersection {
            title
            description
            author
            siteUrl
            keywords
          }
        }
      }
    }
  `
  );

  let metadata = site.siteMetadata;

  switch( category ) {
    case "ultrarunning":
      metadata = site.siteMetadata.ultrarunning;
      break;
    case "science-society":
      metadata = site.siteMetadata.science_society;
      break;
    case "tech-intersection":
      metadata = site.siteMetadata.tech_intersection;
      break;
    default:
  }

  const lang = "en-US";
  const xmlns = "http://ogp.me/ns#"
  let metaDescription = description || metadata.description;
  let seoTitle = title || metadata.title;
  let seoUrl = url || metadata.siteUrl;
  let seoKeywords = keywordsString || metadata.keywords;

  if (share) {
    if (share.excerpt)
      metaDescription = share.excerpt;

    seoUrl = share.url;
    seoTitle = share.title;

    if (share.tagsString)
      seoKeywords = share.tagsString;
  }

  if (metaDescription.length > 160)
    metaDescription = metaDescription.substring(0, 160) + '...';

  return (
    <Helmet
      htmlAttributes={{
        lang,
        "xmlns:og": xmlns, 
      }}
      title={seoTitle}
      //titleTemplate={`%s | ${site.siteMetadata.title}`}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: seoTitle,
        },
        {
          property: `og:url`,
          content: seoUrl,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: seoTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: seoKeywords,
        }
      ]
        .concat(meta)
      }
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  category: ``,
}

Seo.propTypes = {
  url: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywordsString: PropTypes.string,
  category: PropTypes.string.isRequired,
  title: PropTypes.string,
}

export default Seo
