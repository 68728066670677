
import React from "react";

import "../styles/flex.less";

const Sidebar = props => (
<div className="flex-sidebar-content">
    <div className="sidebar-h5">{props.title}</div> 
{/* <div style={{textAlign: 'left'}}>     */}
    {props.content}
{/* </div> */}
</div>
);

export default Sidebar;
